import store from '@/state/store'

export default [
  {
    path: '/',
    meta: { authRequired: true },
    name: 'home',
    component: () => import('./views/home'),
  },
  {
    path: '/details/:uuid',
    meta: { authRequired: true },
    name: 'applications-details',
    component: () => import('./views/applications/details')
  },
  {
    path: '/acceptor-change/:uuid',
    name: 'acceptor-change',
    component: () => import('./views/applications/acceptor-change'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_APPLICANT")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '/auth/social-callback/:code',
    name: 'Social social-callback',
    component: () => import('./views/social-callback'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/login/:error?',
    name: 'login',
    component: () => import('./views/login1'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/change-password/:token',
    name: 'change-password',
    component: () => import('./views/change-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/my-profile',
    name: 'Mój profil',
    component: () => import('./views/account/account-my-profile'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/tax-transfer',
    name: 'Przelew skarbowy',
    component: () => import('./views/tax-transfer/tax-transfer'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_APPLICANT")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
    children: [
      {
        name: 'Przelew skarbowy - wnioskujący',
        path: '/tax-transfer/applicant',
      },
      {
        name: 'Przelew skarbowy - dane firmy',
        path: '/tax-transfer/company',
      },
      {
        name: 'Przelew skarbowy - dostawca',
        path: '/tax-transfer/provider',
      },
      {
        name: 'Przelew skarbowy - dane przelewu',
        path: '/tax-transfer/transfer',
      },
      {
        name: 'Przelew skarbowy - podsumowanie',
        path: '/tax-transfer/summary',
      },
    ]
  },
  {
    path: '/internal-transfer',
    name: 'Przelew krajowy',
    component: () => import('./views/internal-transfer/internal-transfer'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_APPLICANT")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
    children: [
      {
        name: 'Przelew krajowy - wnioskujący',
        path: '/internal-transfer/applicant',
      },
      {
        name: 'Przelew krajowy - dane firmy',
        path: '/internal-transfer/company',
      },
      {
        name: 'Przelew krajowy - dostawca',
        path: '/internal-transfer/provider',
      },
      {
        name: 'Przelew krajowy - dane przelewu',
        path: '/internal-transfer/transfer',
      },
      {
        name: 'Przelew krajowy - podsumowanie',
        path: '/internal-transfer/summary',
      },
    ]
  },
  {
    path: '/international-transfer',
    name: 'Przelew zagraniczny',
    component: () => import('./views/international-transfer/international-transfer'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_APPLICANT")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
    children: [
      {
        name: 'Przelew zagraniczny - wnioskujący',
        path: '/international-transfer/applicant',
      },
      {
        name: 'Przelew zagraniczny - dane firmy',
        path: '/international-transfer/company',
      },
      {
        name: 'Przelew zagraniczny - dostawca',
        path: '/international-transfer/provider',
      },
      {
        name: 'Przelew zagraniczny - dane przelewu',
        path: '/international-transfer/transfer',
      },
      {
        name: 'Przelew zagraniczny - podsumowanie',
        path: '/international-transfer/summary',
      },
    ]
  },
  {
    path: '/prepaid-card-transfer',
    name: 'Zasilenie karty',
    component: () => import('./views/prepaid-card-transfer/prepaid-card-transfer'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"].roles.includes("ROLE_APPLICANT")) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
    children: [
      {
        name: 'Zasilenie karty - wnioskujący',
        path: '/prepaid-card-transfer/applicant',
      },
      {
        name: 'Zasilenie karty - dane firmy',
        path: '/prepaid-card-transfer/company',
      },
      {
        name: 'Zasilenie karty - szczegóły karty',
        path: '/prepaid-card-transfer/details',
      },
      {
        name: 'Zasilenie karty - dane przelewu',
        path: '/prepaid-card-transfer/transfer',
      },
      {
        name: 'Zasilenie karty - podsumowanie',
        path: '/prepaid-card-transfer/summary',
      },
    ]
  },
  {
    path: '/notifications/:type/:module',
    name: 'Moje powiadomienia',
    component: () => import('./views/notifications/notifications'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/history',
    name: 'Wszystkie wnioski',
    component: () => import('./views/history'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          store.getters["auth/loggedIn"].roles.includes("ROLE_APPLICANT") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_ACCEPTING") ||
          store.getters["auth/loggedIn"].roles.includes("ROLE_ACCEPTING_CARD")
        ) {
          next()
        } else {
          next({ ...routeFrom })
        }
      },
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('./views/errors/error-404'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/errors/404',
    name: 'error-404',
    component: () => import('./views/errors/error-404'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/errors/500',
    name: 'error-500',
    component: () => import('./views/errors/error-500'),
    meta: {
      authRequired: true,
    }
  },
]
