import AuthRepository from './core/auth-repository';
import UserAccountRepository from './core/user-accounts-repository';
import NotificationRepository from './core/notifications-repository';
import ApplicantsCompanyRepository from './core/applicants-companies-repository';
import AcceptorRepository from './core/acceptors-repository';
import ProviderRepository from './payments/providers-repository';
import PrepaidCardRepository from './payments/prepaid-card-repository';
import PaymentCompanyRepository from './payments/companies-repository';
import PaymentBankRepository from './payments/banks-repository';
import PaymentApplicationRepository from './payments/applications-repository';
import AttachmentRepository from './payments/attachments-repository';
import PaymentApplicationAttachmentRepository from './payments/applications-attachments-repository';


const repositories = {
  'AuthRepository': AuthRepository,
  'UserAccountRepository': UserAccountRepository,
  'NotificationRepository': NotificationRepository,
  'ApplicantsCompanyRepository': ApplicantsCompanyRepository,
  'AcceptorRepository': AcceptorRepository,
  'ProviderRepository': ProviderRepository,
  'PaymentCompanyRepository': PaymentCompanyRepository,
  'PaymentBankRepository': PaymentBankRepository,
  'PaymentApplicationRepository': PaymentApplicationRepository,
  'PrepaidCardRepository': PrepaidCardRepository,
  'AttachmentRepository': AttachmentRepository,
  'PaymentApplicationAttachmentRepository': PaymentApplicationAttachmentRepository,
};

export default {
  get: name => repositories[name]
};
