import Client from '@/app/repository/axios-client';

const resource = '/app-user/payments/my-applications';

export default {
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  getGrid(parameters) {
    return Client.get(`${resource}/grid` + parameters);
  },
  getSchedule(id) {
    return Client.get(`${resource}/${id}/cycle-of-applications`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  changeAcceptor(payload, id) {
    return Client.put(`${resource}/${id}/change-acceptor`, payload);
  },
  changeStatus(payload, id) {
    return Client.put(`${resource}/${id}/change-status`, payload);
  },
  checkAndSave(payload, id) {
    return Client.put(`${resource}/${id}/check-settlement`, payload);
  },
};
