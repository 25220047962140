import Vue from 'vue';
import moment from 'moment';
import { typeOfRoles } from "@/data/data-type-of-roles";

Vue.filter('formattedDate', value => {
  return moment(value).format('YYYY-MM-DD')
})

Vue.filter('formattedYearDate', value => {
  return moment(value).format('YYYY')
})

Vue.filter('stringToDate', value => {
  return moment(value, 'LL').toDate()
})

Vue.filter('nameLetters', value => {
  let fullName = value.toString().split(" ");
  let firstNameLetter = fullName[0].substring(0, 1).toLocaleUpperCase();
  let lastNameLetter = fullName[fullName.length - 1].substring(0, 1).toLocaleUpperCase();
  return firstNameLetter + lastNameLetter;
})

Vue.filter('formatCreditCardNumber', value => {
  value = value.split('');
  let newVal = '';
  for(let i = 0; i < value.length; i++) {
    if(i % 4 == 0 && i > 0) {
      newVal = newVal.concat(' ');
    }
    newVal = newVal.concat(value[i]);
  }
  return newVal;
})

Vue.filter('roleName', value => {
  let arr = typeOfRoles.filter((obj) => {
    return obj.value === value
  }).map(obj => obj.text);
  return arr[0]
})

Vue.filter('rekord', value => {
  if (value == 1) {
    return value + ' rekord'
  } else {
    if (value % 10 === 2 || value % 10 === 3 || value % 10 === 4) {
      return value + ' rekordy'
    } else {
      return value + ' rekordów'
    }
  }
})

Vue.filter('wystąpienie', value => {
  if (value == 1) {
    return value + ' wystąpienie'
  } else {
    if (value % 10 === 2 || value % 10 === 3 || value % 10 === 4) {
      return value + ' wystąpienia'
    } else {
      return value + ' wystąpień'
    }
  }
})

Vue.filter('formatAccNo', value => {
  if (value != undefined && value != null) {
    if (value.length == 26) {
      value = value.replace(/(\d{2})\s?(\d{4})\s?(\d{4})\s?(\d{4})\s?(\d{4})\s?(\d{4})\s?(\d{4})/, "$1 $2 $3 $4 $5 $6 $7");
    } else {
      // value = value.replace(/(\d{4})(?=\d)/g, "$1 ");
    }
  }
  return value;
})

Vue.filter('formatPrepaidCardNo', value => {
  if (value != undefined && value != null) {
    if (value.length == 16) {
      value = value.replace(/(\d{4})(?=\d)/g, "$1 ");
    }
  }
  return value;
})