import Client from '@/app/repository/axios-client';

const resource = '/app-user/payments/banks';

export default {
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  getAll() {
    return Client.get(`${resource}/`);
  },
  getGrid(parameters) {
    return Client.get(`${resource}/grid` + parameters);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(payload, id) {
    return Client.put(`${resource}/${id}`, payload);
  },
};
