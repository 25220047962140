import Client from '@/app/repository/axios-client';

const resource = '/core/notifications';

export default {
  getNotifications(page = 1, maxItems = 10, onlyUnread = false, module = 'all') {
    return Client.get(`${resource}?page=${page}&maxItems=${maxItems}&onlyUnread=${onlyUnread}&module=${module}`);
  },
  markAsRead(uuidNotification) {
    return Client.put(`${resource}/mark-as-read/${uuidNotification}`);
  },
  markAsUnread(uuidNotification) {
    return Client.put(`${resource}/mark-as-unread/${uuidNotification}`);
  },
  markAsReadAll() {
    return Client.put(`${resource}/mark-as-read-all`);
  },

};
