const typeOfRoles = [
  { index: 1, value: "ROLE_APPLICANT", text: "Wnioskujący" , app: "Aplikacja użytkownika"},
  { index: 2, value: "ROLE_ACCEPTING", text: "Akceptujący przelewy krajowe, zagraniczne, skarbowe" , app: "Aplikacja użytkownika"},
  { index: 8, value: "ROLE_ACCEPTING_CARD", text: "Akceptujący zasilenia kart" , app: "Aplikacja użytkownika"},
  { index: 3, value: "ROLE_SUPER_ACCEPTING", text: "Akceptujący wyższego stopnia" , app: "Aplikacja administratora"},
  { index: 4, value: "ROLE_FINANCIAL_OPERATIONS", text: "Dział operacji finansowych" , app: "Aplikacja administratora"},
  { index: 5, value: "ROLE_ADMIN", text: "Administrator systemu" , app: "Aplikacja administratora"},
  { index: 6, value: "ROLE_ACCEPTING_KRS", text: "Akceptujący karty nierozliczone" , app: "Aplikacja użytkownika"},
  { index: 7, value: "ROLE_PRODUCT_ADMINISTRATOR", text: "Administrator produktu finansowego" , app: "Aplikacja administratora"},
];

export { typeOfRoles };
