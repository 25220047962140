import Client from '@/app/repository/axios-client';

const resource = '/app-user/payments/providers';

export default {
  search(searchText, businessUnitCode) {
    const searchParams = "?search=" + searchText + "&company=" + businessUnitCode ;
    return Client.get(`${resource}` + searchParams);
  }
};
