import Client from '@/app/repository/axios-client';

const resource = '/app-user/core/applicants-companies';

export default {
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  getAll() {
    return Client.get(`${resource}/`);
  },
};
