import Client from '@/app/repository/axios-client';

const resource = '/app-user/core/companies';

export default {
  getAcceptor(companyId, id) {
    return Client.get(`${resource}/${companyId}/acceptors/${id}`);
  },
  getAllAcceptors(companyId) {
    return Client.get(`${resource}/${companyId}/acceptors/`);
  },
  getAllCardsAcceptors(companyId, parameters) {
    return Client.get(`${resource}/${companyId}/acceptors/cards` + parameters);
  },
  getHigherOrderAcceptors() {
    return Client.get(`${resource}/higher-order-acceptors`);
  },
};
