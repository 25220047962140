import {authJWTService} from "@/app/service/authJWTService";
import router from '@/router/routes';

export const state = {
  currentUser: JSON.parse(localStorage.getItem('currentUser')),
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('currentUser', newValue)
  },

  SET_CURRENT_USER_PROFILE_IMAGE(state, profileImage) {
    state.currentUser.profileImage = profileImage;
    saveState('currentUser', state.currentUser)
  },

  SET_TOKEN_USER(state, newValue) {
    state.currentUser = newValue
    saveState('currentUser', newValue)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    if (state.currentUser === null) {
      return false;
    }

    return state.currentUser
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate')
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {

    if (getters.loggedIn) return dispatch('validate')

    return authJWTService.login(email, password)
      .then(
        user => {
          commit('SET_CURRENT_USER', user)
          router.push('/');
          return user;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  logInSocial({ commit, dispatch, getters }, { token } = {}) {

    if (getters.loggedIn) return dispatch('validate')

    return authJWTService.loginSocial(token)
      .then(
        user => {
          commit('SET_CURRENT_USER', user)
          router.push('/');
          return user;
        },
        error => {
          return Promise.reject(error)
        }
      );
  },

  // Logs out the current user.
  logOut({commit}) {
    authJWTService.logout();
    commit('SET_CURRENT_USER', null);
    return Promise.reject('logout');
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    return authJWTService.resetPassword(email);
  },

  // eslint-disable-next-line no-unused-vars
  changePasswordWithToken({ commit, dispatch, getters }, { payload, token } = {}) {
    return authJWTService.changePasswordWithToken(payload, token);
  },

  // eslint-disable-next-line no-unused-vars
  changeProfileImage({ commit, dispatch, getters }, { profileImage } = {}) {
    console.log(profileImage);
    commit('SET_CURRENT_USER_PROFILE_IMAGE', profileImage);
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser || state.currentUser === 'null') {
      return false;
    } else {
      return state.currentUser;
    }
  },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
