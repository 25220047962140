import Client from '@/app/repository/axios-client';

const resource = '/app-user/payments/prepaid-cards';

export default {
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  getAll() {
    return Client.get(`${resource}/`);
  },
  getGrid(parameters) {
    return Client.get(`${resource}/grid` + parameters);
  },
  checkCard(parameters) {
    return Client.get(`${resource}/check?` + parameters);
  },
  getPrepaidCardsByCompanyId(id) {
    return Client.get(`${resource}?companyId=${id}`);
  }
};