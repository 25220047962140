import Client from '@/app/repository/axios-client';

const resource = '/app-user/payments/companies';

export default {
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  getAll() {
    return Client.get(`${resource}/`);
  },
  getGrid(parameters) {
    return Client.get(`${resource}/grid` + parameters);
  },
  getBankTypes(id) {
    return Client.get(`${resource}/${id}/bank-account-types`);
  },
};
