import Client from '@/app/repository/axios-client';
import ClientBaseDomain from '@/app/repository/axios-client-base-domain'

const resource = '/core/user-accounts';

export default {
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  getMyAccount() {
    return Client.get(`${resource}/my-account`);
  },
  getGrid(parameters) {
    return Client.get(`${resource}/grid` + parameters);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(payload, id) {
    return Client.put(`${resource}/${id}`, payload);
  },
  changeProfileImage(payload) {
    return Client.put(`${resource}/change-profile-image`, payload);
  },
  changePassword(payload) {
    return Client.put(`${resource}/change-password`, payload);
  },
  resetPassword(payload) {
    return ClientBaseDomain.put(`/reset-password`, payload);
  },
  changePasswordWithToken(payload, token) {
    return ClientBaseDomain.put(`/change-password/${token}`, payload);
  }
};
